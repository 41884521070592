import { useAccount, useAccountInstitute } from './use-authenticate';
// ----------------------------------------------------------------------
/**
 * 
 * @returns {{ 
 * id: number
 * createdAt: string
 * updatedAt: string
 * email: string
 * verified: boolean
 * role:{
 *  type: 'institute' | 'student' | 'parent' | 'employee'
 * }
 * }}
 */
export function useInstitute() {
  const account = useAccount();
  const institute = useAccountInstitute();
  if(account?.role?.type === 'institute'){
    return account
  }
  return  institute??{};
}

