
import { useAccount } from 'src/auth';
import { useInstitute } from 'src/auth/hooks/use-institute';
import { useMemo } from 'react';
import { getEmployeeNavigation, getInstituteNavigation, getParentNavigation, getStudentNavigation } from '../config-navigation';

// ----------------------------------------------------------------------

const useDashboardNavigation = ()=>{
  const account = useAccount()
  const institute = useInstitute()
  const navigations = useMemo(()=>{
    if(account?.role?.type === 'institute'){
      return getInstituteNavigation(institute)
    }
    if(account?.role?.type === 'student'){
      return getStudentNavigation(account, institute)
    }
    if(account?.role?.type === 'parent'){
      return getParentNavigation(account, institute)
    }
    if(account?.role?.type === 'employee'){
      return getEmployeeNavigation(account, institute)
    }
    return []
  },[account, institute])
  return navigations
}

export default useDashboardNavigation