import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

// ----------------------------------------------------------------------

export default function ExaminationLayout({ children }) {

  return (
   
      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        {children}
      </Box>
   
  );
}

ExaminationLayout.propTypes = {
  children: PropTypes.node,
};
