import axios from "axios"

import { Result } from "src/utils/result"
import { authHttpClient } from "src/http-clients/auth-http-client"
import { AuthDB } from "src/config"


export const login = async (type, username, password) => {
    try {
        const result = await authHttpClient.post(`/api/${type}/auth/local`, {
            "identifier": username,
            "password": password
        })
        return Result.ok(result.data, Result.getMessage(result.data))
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return Result.error(error.response.data, Result.getMessage(error.response.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}
export const loginInstitute =  (username, password) => login('institues', username, password)

export const registerInstitute = async ({ email, password }) => {
    try {
        const result = await authHttpClient.post('/api/institutes/auth/local/register', {
            "email": email,
            "password": password,
        })
        return Result.ok(result.data, Result.getMessage(result.data, "Registration successful"))
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return Result.error(error.response.data, Result.getMessage(error.response.data, 'Registration failed'))
        }
        return Result.error(error, Result.getMessage(error, 'Registration failed due to unexpected issue.'))
    }
}


export const completeRegistration = async (email, verificationCode) => {
    try {
        const result = await authHttpClient.post('/user/completeRegistration', {
            "email": email,
            "verificationCode": verificationCode
        })
        return Result.ok(result.data, Result.getMessage(result.data))
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return Result.error(error.response.data, Result.getMessage(error.response.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}

export const retriggerVerificationCode = async (email) => {
    try {
        const result = await authHttpClient.post('/user/retriggerVerificationCode', {
            "email": email,
        })
        return Result.ok(result.data, Result.getMessage(result.data))
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return Result.error(error.response.data, Result.getMessage(error.response.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}

export const getAuthData = async (key) => {
    try {
        const storeData = await AuthDB.get(key)
        if (storeData) {
            return Result.ok(storeData)
        }
        return Result.error(null, `Unable to retrieve auth data ${key}`)
    } catch (error) {
        return Result.error(null, Result.getMessage(error,`Unable to retrieve auth data ${key}`))
    }
}

export const setAuthData = async (key, data) => {
    try {
        console.log('toStoreData', key, data)
        try {
        const storeData = await AuthDB.get(key)
        console.log('storeData', key, storeData)
        if (storeData) {
            const result = await AuthDB.put({ _rev: storeData._rev, _id: key, data })
            return Result.ok(result)
        }
        } catch (_) { /* empty */ }
        const result = await AuthDB.put({ _id: key, data })
        return Result.ok(result)
    } catch (error) {
        console.error('storeData', key, error)
        return Result.error(null, Result.getMessage(error,`Unable to put auth data ${key}`))
    }
}


export const getAuthToken = async () => {
    const storeToken = await getAuthData('token')
    if (storeToken.ok) {
        return Result.ok({ data: storeToken.data })
    }
    return storeToken
}


export const setAuthToken = async (token) => {
    const storeToken = await setAuthData('token', token)
    if (storeToken.ok) {
        return Result.ok({ data: storeToken.data })
    }
    return Result.error(null, 'decrypt data doesn\'t contain property data.decryptedToken')
}

export const resetPassword = async (currentPassword, newPassword) => {
    const account = getAuthData()
    const { email } = account
    const authToken = await getAuthToken()
    try {
        const result = await authHttpClient.put('/user/resetPassword', {
            "email": email,
            "currentPassword": currentPassword,
            "newPassword": newPassword
        }, {
            headers: {
                'Authorization': `Bearer ${authToken.data}`
            }
        })
        return Result.ok(result.data, Result.getMessage(result.data))
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return Result.error(error.response.data, Result.getMessage(error.response.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}