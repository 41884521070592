import axios from 'axios'
import { config } from 'src/config'

/**
 * @type {import('axios').AxiosInstance & {isAxiosError: (error:any)=> boolean}}
 */
export const authHttpClient = axios.create({
    baseURL: config.apiBaseURL
})
// authHttpClient.interceptors.request.use((req) =>{
//     return req
// })
authHttpClient.isAxiosError = axios.isAxiosError
