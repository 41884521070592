import PropTypes from 'prop-types';
// import {  useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
// ----------------------------------
export default function CustomIcon ({onClick, size, icon}) {
    size = size || '24px'
    const [extension, iconName=''] = icon.split(':')
    return (
    <Box component="span" onClick= {onClick} sx={{display:"inline-table"}}>
        <img  draggable="false"  style={{width:size, height: size}} src={`/assets/icons/${iconName.replace('.', '/')}.${extension}`} alt={icon}/>
    </Box>)
  }
CustomIcon.propTypes = {
    size:PropTypes.oneOfType([ PropTypes.number, PropTypes.string]),
    onClick: PropTypes.func,
    icon: PropTypes.string.isRequired
};