/* eslint-disable react/no-unknown-property */
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
// import { useTheme } from '@mui/material/styles';

import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, variant="small", sx, ...other }, ref) => {
  // const theme = useTheme();

  // const PRIMARY_LIGHT = theme.palette.primary.light;

  // const PRIMARY_MAIN = theme.palette.primary.main;

  // const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );
  const variation = {
    "small":{
      width: 40,
      height: 40,
    },
    "medium":{
      width: 80,
      height: 80,
    },
    "large":{
      width: 140,
      height: 140,
    }
  }
  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        ...variation[variant],
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
     <img src="/assets/logo.svg" alt= "logo.svg"/>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  variant: PropTypes.oneOf(['small', 'medium', 'large']),
  sx: PropTypes.object,
};

export default Logo;
