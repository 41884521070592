import { useContext } from 'react';

import { AuthContext } from '../auth-context';
// ----------------------------------------------------------------------
/**
 * 
 * @returns {{ 
 * id: number
 * createdAt: string
 * updatedAt: string
 * email: string
 * verified: boolean
 * role:{
 *  type: 'institute' | 'student' | 'parent' | 'employee'
 * }
 * institute: Record<string, any>
 * }}
 */
export function useAccount() {
  const {account} = useAuthorization();
  return  account ;
}


/**
 * 
 * @returns {{ 
* id: number
* createdAt: string
* updatedAt: string
* email: string
* verified: boolean
* role:{
*  type: 'institute' | 'student' | 'parent' | 'employee'
* }
* institute: Record<string, any>
* }}
*/
export function useAccountInstitute() {
 const {institute} = useAuthorization();
 return  institute ;
}

/**
 * 
 * @returns {import('src/model/profile').Profile}
 */
export function useProfile() {
  const {profile} = useAuthorization();
  return  profile;
}

/**
 * 
 * @returns {(profile: import('src/model/profile').Profile)=> void}
 */
export function useSetProfile() {
  const {setProfile} = useAuthorization();
  return  setProfile;
}
/**
 * 
 * @returns {[{ 
* createdAt: string
* updatedAt: string
* email: string
* verified: boolean
* role:{
*  type: 'institute' | 'student' | 'parent' | 'employee'
* }
* },
* Record<string,any>
* ]}
*/
export function useAccountProfile() {
  const {account,profile} = useAuthorization();
  return  [account, profile] ;
}
/**
 * 
 * @returns {{ 
* login: (token: string) => Promise<any>
* setProfile: (data: Record<string,any>) => any
* setAccount: (data: Record<string,any>) => any
* setInstitute: (data: Record<string,any>) => any
* account: {
*    username: string;
*    role:{
*     name: string;
*     type: 'institue' | 'student' | 'parent' | 'employee'
*    }
*  }
* institute: Record<string,any>
* profile: Record<string,any>
* }}
*/
export const useAuthorization = () => useContext(AuthContext);